<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Team</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="teams-form">
          <v-text-field
            label="Display Name *"
            v-model="fields.display_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('display_name')"
            :error-messages="errors['display_name']"
          ></v-text-field>
          <v-text-field
            label="Username *"
            v-model="fields.username"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('username')"
            :error-messages="errors['username']"
          ></v-text-field>
          <v-text-field
            label="Password *"
            v-model="fields.password"
            type="password"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('password')"
            :error-messages="errors['password']"
          ></v-text-field>
          <v-switch
            label="Visible on Website"
            v-model="fields.visible"
            inset
            :error="errors.hasOwnProperty('visible')"
            :error-messages="errors['visible']"
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="teams-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        display_name: null,
        username: null,
        password: null,
        visible: false,
      },
      team: null,
      errors: {},
    };
  },

  methods: {
    openForm: function (team = null) {
      if (team !== null) {
        this.isEditing = true;
        this.team = team;
        this.fields.display_name = team.display_name;
        this.fields.username = team.username;
        this.fields.password = team.password;
        this.fields.visible = team.visible;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.team = null;
      this.fields.display_name = null;
      this.fields.username = null;
      this.fields.password = null;
      this.fields.visible = false;

      this.errors = {};
    },

    saveForm: function () {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.teamId = this.team.id;
      }

      this.$store
        .dispatch("includem/teamsStore/saveTeam", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
