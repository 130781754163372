<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Teams</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Team"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.teamForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add Team</span>
            </v-tooltip>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light>
              <v-icon left>mdi-archive</v-icon>View Archived
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="teams"
            no-data-text="No teams found"
          >
            <template v-slot:item.full_name="{ item }">
              <router-link
                :to="{
                  name: 'module-includem-teams-individual',
                  params: { customerId: item.id },
                }"
                >{{ item.full_name }}</router-link
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.teamForm.openForm(item)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-btn
                x-small
                depressed
                class="mr-2"
                color="green lighten-4 green--text"
                :to="{
                  name: 'module-includem-teams-individual',
                  params: { customerId: item.id },
                }"
              >
                <v-icon x-small left>mdi-eye</v-icon> View
              </v-btn>
              <v-tooltip bottom v-if="!item.bios">
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="openDeleteTeam(item)"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
        <v-dialog v-model="deleteTeam.dialog" persistent max-width="400">
          <v-card>
            <v-card-title class="headline">Delete Team</v-card-title>
            <v-card-text
              >Are you sure you wish to delete
              {{ deleteTeam.team.title }}?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" depressed @click="resetDeleteTeam"
                >No, cancel</v-btn
              >
              <v-btn
                color="success"
                depressed
                :loading="deleteTeam.loading"
                @click="saveDeleteTeam"
                >Yes</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>

    <TeamForm ref="teamForm" />
  </div>
</template>

<script>
import TeamForm from "./components/TeamForm";

export default {
  components: {
    TeamForm,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Includem",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Teams",
          disabled: false,
          exact: true,
          to: {
            name: "module-includem-teams",
            params: { id: this.$route.params.id },
          },
        },
      ],
      searchTerm: "",
      tableHeaders: [
        { text: "Display Name", value: "display_name" },
        { text: "Username", value: "username" },
        { text: "Visible", value: "visible" },
        { text: "Actions", value: "actions", align: "end" },
      ],
      deleteTeam: {
        dialog: false,
        team: {},
        loading: false,
      },
    };
  },

  computed: {
    teams() {
      let teams = this.$store.getters["includem/teamsStore/all"];

      if (this.searchTerm !== "") {
        teams = teams.filter((c) => {
          const name = c.full_name.toLowerCase();
          const email = c.email.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm) || email.includes(searchTerm);
        });
      }

      return teams;
    },
  },

  methods: {
    openDeleteTeam(team) {
      this.deleteTeam.team = team;
      this.deleteTeam.dialog = true;
    },

    resetDeleteTeam() {
      this.deleteTeam.dialog = false;
      this.deleteTeam.team = {};
      this.deleteTeam.loading = false;
    },

    saveDeleteTeam() {
      this.deleteTeam.loading = true;

      this.$store
        .dispatch("includem/teamsStore/deleteTeam", {
          appId: this.$route.params.id,
          teamId: this.deleteTeam.team.id,
        })
        .then(() => {
          this.resetDeleteTeam();
        })
        .catch(() => {
          this.deleteTeam.loading = false;
        });
    },
  },
};
</script>
